import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import DeleEspagnol1 from '../assets/images/dele-espagnol/college-lycee-international-lafayette-dele-espagnol1.png';
import DeleEspagnol2 from '../assets/images/dele-espagnol/college-lycee-international-lafayette-dele-espagnol2.png';
import {Helmet} from "react-helmet";
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import '../../translation';
import { useTranslation } from 'react-i18next';

const Scolarite = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    const t = useTranslation('scolarite').t;

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>EIB Campus Pégomas - Centre d'examen Cambridge</title>
                    <meta name="description" content="EIB Campus Pégomas - L'EIB Campus Pégomas est un Centre d’examens de Cambridge et prépare aux diplômes suivants : Starters – Movers – Flyers" />
                </Helmet>
                <Sidebar />
                <div className='content fadeInEarth text'>
                    <div className='special-title'>
                       Scolarité
                    </div>
                    <div className='content-title mb-0'>
                        {t('text1')}
                    </div>
                    <p dangerouslySetInnerHTML={{__html: t('text2')}}></p><br/>
                    <div className='content-title mb-0'>
                        {t('text3')}
                    </div>
                    <p dangerouslySetInnerHTML={{__html: t('text4')}}></p>

                </div>
            </div>
            <Footer />
        </>

    )
}

export default Scolarite;